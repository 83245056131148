import React, { useCallback } from 'react';
import { Section, RichText } from '../../common';
import MobileTicker from './MobileTicker';
import DesktopTicker from './DesktopTicker';
import useIsMobile from '../../../hooks/useIsMobile';
import { isClient } from '../../../utils/helpers';
import './ManifestoSlice.scss';

const ManifestoSlice = ({ slice }) => {
  const {
    title,
    featuredText,
    body,
  } = slice;

  const isMobile = useIsMobile();

  const scrollToVideoPlayer = useCallback(() => {
    const targetEl = document.getElementById('video-stream');
    if (targetEl) targetEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  return (
    <section className="manifesto-slice">
      <Section hasPadding hasBottomBorder>
        <div className="grid-container">
          <h2 className="manifesto-title">{title}</h2>
        </div>
        {isClient && isMobile && (
          <MobileTicker />
        )}
        <div className="grid-container">
          <p className="manifesto-featured-text">{featuredText}</p>
        </div>
        <div className="grid-container">
          <RichText className="manifesto-body" blocks={body || []} />
        </div>
        <button
          type="button"
          className="scroll-to-button"
          onClick={() => scrollToVideoPlayer()}
          aria-label="Jump to the video stream"
        >
          Watch Now
        </button>
      </Section>
      {isClient && !isMobile && (
        <DesktopTicker />
      )}
    </section>
  );
};

export default ManifestoSlice;
