import Layout from './Layout';
import Header from './Header';
import Footer from './Footer';
import PageTransition from './PageTransition';

export {
  Header,
  Footer,
  Layout,
  PageTransition,
};

export default Layout;
