import React from 'react';
import { Logo } from '../common';
import './Header.scss';

const Header = () => (
  <header className="header">
    <Logo />
  </header>
);

export default Header;
