import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import gsap from 'gsap';
import { ReactComponent as HorizontalTickerText } from '../../../assets/images/HorizontalTickerText.svg';
import { isClient } from '../../../utils/helpers';
import './MobileTicker.scss';

const MobileTicker = () => {
  const tickerRef = useRef();
  const tickerTimelineRef = useRef();
  const [inViewRef, inView] = useInView({ threshold: 0, triggerOnce: false });

  useEffect(() => {
    if (isClient && tickerRef.current) {
      const { width } = tickerRef.current.getBoundingClientRect();
      const duration = width / 150;
      if (width > window.innerWidth) {
        tickerTimelineRef.current = gsap.timeline({ repeat: -1 });
        tickerTimelineRef.current.fromTo(tickerRef.current, { x: '-50%' }, {
          duration,
          ease: 'none',
          x: '0%',
        });
        return () => {
          tickerTimelineRef.current.kill();
          tickerTimelineRef.current = null;
        };
      }
    }
  }, []);

  useEffect(() => {
    if (tickerTimelineRef.current) {
      if (inView) {
        tickerTimelineRef.current.play();
      } else {
        tickerTimelineRef.current.pause();
      }
    }
  }, [inView]);

  return (
    <div className="mobile-horizontal-ticker" ref={inViewRef}>
      <div className="ticker-text" ref={tickerRef}>
        <HorizontalTickerText />
        <HorizontalTickerText />
      </div>
    </div>
  );
};

export default MobileTicker;
