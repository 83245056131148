import { useState } from 'react';
import useDebouncedCallback from './useDebouncedCallback';
import useWindowResize from './useWindowResize';
import { isClient } from '../utils/helpers';

// Hook
const useIsMobile = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [isMobile, setIsMobile] = useState(undefined);

  useWindowResize(useDebouncedCallback(() => {
    if (isClient) {
      setIsMobile(window.innerWidth <= 800);
    }
  }, 200, []));

  return isMobile;
};

export default useIsMobile;
