import React from 'react';

const isProd = process.env.NODE_ENV === 'production';

class ErrorBoundary extends React.Component {
  state = { errorMessage: null, errorDetail: null }

  static getDerivedStateFromError (error) {
    return { errorMessage: error.message };
  }

  componentDidCatch (error, errorInfo) {
    console.error('Error rendering slice', error, errorInfo);
    this.setState({
      errorMessage: error.message,
      errorDetail: errorInfo.componentStack,
    });
  }

  render () {
    const { children } = this.props;
    const { errorMessage, errorDetail } = this.state;

    if (!errorMessage) {
      return children;
    }

    if (!isProd) return null;

    return (
      <div className="error-slice">
        <h2 className="title">Error rendering slice</h2>
        {errorDetail ? (
          <details className="details">
            <summary><code>{errorMessage}</code></summary>
            <pre>{errorDetail}</pre>
          </details>
        ) : (
          <code>{errorMessage}</code>
        )}
      </div>
    );
  }
}

export default ErrorBoundary
