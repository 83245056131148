import { useMemo } from 'react'

export default (playlist, trackIndex, videoRanges, streamSeconds, totalPlaylistDuration) => {
  return useMemo(() => {
    const infront = playlist.slice(0, trackIndex);
    const behind = playlist.slice(trackIndex);
    const ordered = [...behind, ...infront];
    return ordered.map((track, index) => {
      let timeLabel = '';
      const t = new Date();
      const range = videoRanges.find(data => data.id === track.id);
      if (index === 0) {
        t.setSeconds(t.getSeconds() - (streamSeconds - range.start));
        timeLabel = `Started at ${t.toLocaleTimeString()}`;
      } else if (streamSeconds > range.start) {
        const additionalTime = totalPlaylistDuration - streamSeconds;
        t.setSeconds(t.getSeconds() + (additionalTime + range.start));
        timeLabel = `Watch at ${t.toLocaleTimeString()}`;
      } else {
        t.setSeconds(t.getSeconds() + (range.start - streamSeconds));
        timeLabel = `Watch at ${t.toLocaleTimeString()}`;
      }
      return {
        timeLabel,
        ...track,
      };
    });
  }, [trackIndex]);
}