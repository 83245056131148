import React from 'react';
import './Section.scss';

const Section = (props) => {
  const {
    children,
    className,
    hasGrid,
    hasPadding,
    hasBottomBorder,
    noGutters,
  } = props;
  const classNames = `section${className ? ` ${className}` : ''}${hasGrid ? ' has-grid' : ''}${hasPadding ? ' has-padding' : ''}${hasBottomBorder ? ' has-bottom-border' : ''}${!noGutters ? ' has-gutters' : ''}`;
  return (
    <div className={classNames}>
      {children}
    </div>
  );
};

export default Section;
