// From https://gist.github.com/Daniel-Hug/36d26a391ff443941940ced9c4b04b3f
// Wrap wrapper around nodes
// Just pass a collection of nodes, and a wrapper element
export default function wrapAll (nodes, wrapper) {
  // Cache the current parent and previous sibling of the first node.
  const parent = nodes[0].parentNode;
  const { previousSibling } = nodes[0];

  // Place each node in wrapper.
  //  - If nodes is an array, we must increment the index we grab from
  //    after each loop.
  //  - If nodes is a NodeList, each node is automatically removed from
  //    the NodeList when it is removed from its parent with appendChild.
  for (let i = 0; nodes.length - i; wrapper.firstChild === nodes[0] && i++) {
    wrapper.appendChild(nodes[i]);
  }

  // Place the wrapper just after the cached previousSibling,
  // or if that is null, just before the first child.
  const nextSibling = previousSibling ? previousSibling.nextSibling : parent.firstChild;
  parent.insertBefore(wrapper, nextSibling);

  return wrapper;
}
