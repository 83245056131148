import React, { forwardRef } from 'react';
import { Link } from 'gatsby';
import './Logo.scss';

const Logo = forwardRef((props, ref) => (
  <Link className="logo" to="/" aria-label="Return Home" ref={ref}>
    <svg viewBox="0 0 241 91" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.44.44H1.52v25.2h5.8V11.28h.16l8.16 14.36h4.84V.44h-5.76v13.2h-.16L7.44.44z" fill="currentColor" />
      <path d="M39.032 20.44h-9.72v-5h7.2v-5.12h-7.2V5.64h9.64V.44h-15.84v25.2h15.92v-5.2z" fill="currentColor" />
      <path d="M53.19 25.64L60.47.44h-5.96l-4.16 15.48h-.16L46.07.44h-6.48l7.28 25.2h6.32z" fill="currentColor" />
      <path d="M77.53 20.44h-9.72v-5h7.2v-5.12h-7.2V5.64h9.64V.44H61.61v25.2h15.92v-5.2z" fill="currentColor" />
      <path d="M98.448 25.64c-.72-1.08-.88-2.28-1.16-5.72-.24-3.16-1.16-4.6-3.4-5.32 2.28-.56 4.36-2.72 4.36-6.24 0-4.88-3-7.92-9.44-7.92h-9.2v25.2h6.2v-8.8h2.52c2.04 0 2.72 1.04 2.84 3.36.16 3 .44 4.44.92 5.44h6.36zm-12.64-13.68V5.48h2.76c2.36 0 3.52 1.24 3.52 3.28 0 2.2-1.16 3.2-3.52 3.2h-2.76z" fill="currentColor" />
      <path d="M112.657.44h-5.92v25.2h5.8V11.28h.16l8.16 14.36h4.84V.44h-5.76v13.2h-.16l-7.12-13.2z" fill="currentColor" />
      <path d="M147.849 12.16c0-7.24-3.24-12.16-10.08-12.16s-10.08 4.92-10.08 12.16v1.76c0 7.24 3.24 12.16 10.08 12.16s10.08-4.92 10.08-12.16v-1.76zm-6.52 3.12c0 3.92-1.28 5.56-3.56 5.56-2.28 0-3.56-1.64-3.56-5.56V10.8c0-3.92 1.28-5.56 3.56-5.56 2.28 0 3.56 1.64 3.56 5.56v4.48z" fill="currentColor" />
      <path d="M160.433 25.64v-20h6.4V.44h-18.961v5.2h6.28v20h6.281z" fill="currentColor" />
      <path d="M1.52 32.44v25.2h6.32v-25.2H1.52z" fill="currentColor" />
      <path d="M16.415 32.44h-5.92v25.2h5.8V43.28h.16l8.16 14.36h4.84v-25.2h-5.76v13.2h-.16l-7.12-13.2z" fill="currentColor" />
      <path d="M43.887 57.64v-20h6.4v-5.2h-18.96v5.2h6.28v20h6.28z" fill="currentColor" />
      <path d="M68.076 52.44h-9.72v-5h7.2v-5.12h-7.2v-4.68h9.64v-5.2h-15.84v25.2h15.92v-5.2z" fill="currentColor" />
      <path d="M88.994 57.64c-.72-1.08-.88-2.28-1.16-5.72-.24-3.16-1.16-4.6-3.4-5.32 2.28-.56 4.36-2.72 4.36-6.24 0-4.88-3-7.92-9.44-7.92h-9.2v25.2h6.2v-8.8h2.52c2.04 0 2.72 1.04 2.84 3.36.16 3 .44 4.44.92 5.44h6.36zm-12.64-13.68v-6.48h2.76c2.36 0 3.52 1.24 3.52 3.28 0 2.2-1.16 3.2-3.52 3.2h-2.76z" fill="currentColor" />
      <path d="M96.573 32.44h-5.92v25.2h5.8V43.28h.16l8.16 14.36h4.84v-25.2h-5.76v13.2h-.16l-7.12-13.2z" fill="currentColor" />
      <path d="M117.845 32.44l-7.12 25.2h5.84l1.32-5.04h7l1.32 5.04h6.36l-7.12-25.2h-7.6zm3.52 7.24h.16l2.08 7.96h-4.4l2.16-7.96z" fill="currentColor" />
      <path d="M143.098 57.64v-20h6.4v-5.2h-18.96v5.2h6.28v20h6.28z" fill="currentColor" />
      <path d="M151.367 32.44v25.2h6.32v-25.2h-6.32z" fill="currentColor" />
      <path d="M179.862 44.16c0-7.24-3.24-12.16-10.08-12.16s-10.08 4.92-10.08 12.16v1.76c0 7.24 3.24 12.16 10.08 12.16s10.08-4.92 10.08-12.16v-1.76zm-6.52 3.12c0 3.92-1.28 5.56-3.56 5.56-2.28 0-3.56-1.64-3.56-5.56V42.8c0-3.92 1.28-5.56 3.56-5.56 2.28 0 3.56 1.64 3.56 5.56v4.48z" fill="currentColor" />
      <path d="M187.776 32.44h-5.92v25.2h5.8V43.28h.16l8.16 14.36h4.84v-25.2h-5.76v13.2h-.16l-7.12-13.2z" fill="currentColor" />
      <path d="M209.048 32.44l-7.12 25.2h5.84l1.32-5.04h7l1.32 5.04h6.36l-7.12-25.2h-7.6zm3.52 7.24h.16l2.08 7.96h-4.4l2.16-7.96z" fill="currentColor" />
      <path d="M224.884 32.44v25.2h15.76v-5.2h-9.44v-20h-6.32z" fill="currentColor" />
      <path d="M11.48 64.44L8.96 79.12H8.8L6.28 64.44H0l5.12 25.2h6.2l2.76-16.16h.16L17 89.64h6.2l5.12-25.2h-5.88L19.8 79.12h-.16l-2.4-14.68h-5.76z" fill="currentColor" />
      <path d="M48.374 76.16c0-7.24-3.24-12.16-10.08-12.16s-10.08 4.92-10.08 12.16v1.76c0 7.24 3.24 12.16 10.08 12.16s10.08-4.92 10.08-12.16v-1.76zm-6.52 3.12c0 3.92-1.28 5.56-3.56 5.56-2.28 0-3.56-1.64-3.56-5.56V74.8c0-3.92 1.28-5.56 3.56-5.56 2.28 0 3.56 1.64 3.56 5.56v4.48z" fill="currentColor" />
      <path d="M63.928 89.64l4.04-16.8h.16v16.8h5.92v-25.2h-8.44l-3.08 13.8h-.16l-3.12-13.8h-8.88v25.2h5.56v-16.8h.16l4 16.8h3.84z" fill="currentColor" />
      <path d="M92.607 84.44h-9.72v-5h7.2v-5.12h-7.2v-4.68h9.64v-5.2h-15.84v25.2h15.92v-5.2z" fill="currentColor" />
      <path d="M100.606 64.44h-5.92v25.2h5.8V75.28h.16l8.16 14.36h4.84v-25.2h-5.76v13.2h-.16l-7.12-13.2z" fill="currentColor" />
      <path d="M122.958 61.52c-.347 1.413-.84 2.96-1.48 4.64a82.241 82.241 0 01-1.96 4.72h-4.28a195.9 195.9 0 001.12-5.04c.373-1.787.653-3.373.84-4.76h5.48l.28.44z" fill="currentColor" />
      <path d="M123.75 71.92c0 4.4 2.56 6.32 5.96 7.4l3.84 1.2c1.52.48 2.24 1.12 2.24 2.44 0 1.44-1.08 2.32-2.8 2.32-2.52 0-3.84-1.48-3.84-3.88v-.36h-5.64v.56c0 5.12 3.44 8.48 9.52 8.48 5.08 0 8.88-2.76 8.88-7.92 0-4.04-2.4-6.04-6.48-7.4l-3.72-1.24c-1.32-.44-2-1.16-2-2.32 0-1.44 1-2.4 2.64-2.4 2.44 0 3.56 1.56 3.56 3.96v.4h5.6v-.64c0-5.04-3.12-8.52-9.12-8.52-5.28 0-8.64 3.24-8.64 7.92z" fill="currentColor" />
      <path d="M156.671 84.6V69.48h2.04c2.96 0 4.52 1.36 4.52 5.4v4.32c0 4.04-1.56 5.4-4.52 5.4h-2.04zm-6.32 5.04h8.92c7.28 0 10.4-4.4 10.4-11.92v-1.36c0-7.52-3.12-11.92-10.4-11.92h-8.92v25.2z" fill="currentColor" />
      <path d="M176.254 64.44l-7.12 25.2h5.84l1.32-5.04h7l1.32 5.04h6.36l-7.12-25.2h-7.6zm3.52 7.24h.16l2.08 7.96h-4.4l2.16-7.96z" fill="currentColor" />
      <path d="M198.598 73.84l-4.28-9.4h-6.56l7.56 16.2v9h6.12v-9l7.56-16.2h-6.36l-4.04 9.4z" fill="currentColor" />
    </svg>
  </Link>
));

Logo.displayName = 'Logo';

export default Logo;
