import { useEffect } from 'react';

const useWindowResize = (callback) => {
  useEffect(() => {
    window.addEventListener('resize', callback, { passive: true });
    callback();
    return () => {
      window.removeEventListener('resize', callback);
    };
  }, [callback]);
};

export default useWindowResize;
