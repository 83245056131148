import React, { useEffect, useRef, useMemo } from 'react';
import Flickity from 'react-flickity-component';
import gsap from 'gsap';
import PlaylistItem from './PlaylistItem';
import './PlaylistMenu.scss';
import useOrderedTracks from '../../../../hooks/useOrderedTracks';

const flickityOptions = {
  prevNextButtons: false,
  pageDots: false,
  cellAlign: 'left',
  contain: 'true',
};

const PlaylistMenu = ({
  menuOpen,
  playlist,
  trackIndex,
  videoRanges,
  streamSeconds,
  totalPlaylistDuration,
}) => {
  const classes = `${menuOpen ? ` active` : ''}`;

  const orderedTracks = useOrderedTracks(playlist, trackIndex, videoRanges, streamSeconds, totalPlaylistDuration)

  const menuRef = useRef();
  const innerRef = useRef();

  useEffect(() => {
    if (menuRef.current) {
      gsap.set(menuRef.current, {
        display: 'block',
        yPercent: -100,
      }, 0);
      gsap.set(innerRef.current, {
        yPercent: 100,
      }, 0);
    }
  }, []);

  useEffect(() => {
    if (menuRef.current) {
      if (menuOpen) {
        gsap.to(menuRef.current, {
          yPercent: 0,
          duration: 1,
          ease: 'expo.out',
        });
        gsap.to(innerRef.current, {
          yPercent: 0,
          duration: 1,
          ease: 'expo.out',
        });
      } else {
        gsap.to(menuRef.current, {
          yPercent: -100,
          duration: 1,
          ease: 'expo.out',
        });
        gsap.to(innerRef.current, {
          yPercent: 100,
          duration: 1,
          ease: 'expo.out',
        });
      }
    }
  }, [menuOpen]);

  return (
    <div className={`playlist-menu${classes}`} ref={menuRef}>
      <div className="inner-container" ref={innerRef}>
        <div className="playlist-tracks">
          <div className="slider-container">
            <Flickity
              className="slider"
              options={flickityOptions}
              static
            >
              {orderedTracks && orderedTracks.map((track, index) => (
                <PlaylistItem
                  key={track.id}
                  isCurrentlyPlaying={index === 0}
                  isUpNext={index === 1}
                  track={track}
                />
              ))}
            </Flickity>
          </div>
        </div>
      </div>
    </div>
  );
};


export default PlaylistMenu;
