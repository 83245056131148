import React, { useEffect } from 'react';
import get from 'lodash/get';
import { StaticQuery, graphql } from 'gatsby';
import {
  Header,
  Footer,
  PageTransition,
} from '.';
import { SEO } from '..';
import useHasMounted from '../../hooks/useHasMounted';
import '../../sass/global/styles.scss';
import { globalHistory } from '@reach/router'

const PureLayout = (props) => {
  const {
    children,
    location,
    page,
    settings,
  } = props;

  const { pathname } = location;

  const isHome = Boolean(pathname === '/');
  const hasMounted = useHasMounted();

  const mainClasses = `${isHome ? 'home' : location.pathname.replace(/\//g, '')}${hasMounted ? ` mounted` : ` unmounted`}`;

  const metaTitle = get(page, 'seo.metaTitle', null);
  const metaDescription = get(page, 'seo.metaDescription', null);
  const metaImage = get(page, 'seo.metaImage.asset.url', null);

  const setViewportHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    setViewportHeight();
    window.addEventListener('resize', setViewportHeight);
    return () => {
      window.removeEventListener('resize', setViewportHeight);
    };
  }, []);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        document.body.classList.remove('modal-open');
      }
    })
  }, [])

  return (
    <>
      <SEO
        title={metaTitle}
        desc={metaDescription}
        banner={metaImage}
        pathname={location.pathname}
      />
      <div id="app" className="app">
        <Header />
        <main className={mainClasses}>
          <PageTransition pathname={pathname}>
            {children}
          </PageTransition>
        </main>
        <Footer settings={settings} />
      </div>
    </>
  );
};

const Layout = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        page: sanityPage {
          seo {
            metaTitle: meta_title
            metaDescription: meta_description
            metaImage: meta_image {
              asset {
                url
              }
            }
          }
        }
        settings: sanitySettings {
          socialLinks {
            title
            url
          }
          footerContact:_rawFooterContact
          footerHeadline
        }
      }
    `}
    render={data => <PureLayout {...props} page={data.page} settings={data.settings} />}
  />
);

export default Layout;
