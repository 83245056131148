import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import gsap from 'gsap';
import { ReactComponent as VerticalTickerText } from '../../../assets/images/VerticalTickerText.svg';
import { isClient } from '../../../utils/helpers';
import './DesktopTicker.scss';

const DesktopTicker = () => {
  const tickerRef = useRef();
  const tickerTimelineRef = useRef();
  const [inViewRef, inView] = useInView({ threshold: 0, triggerOnce: false });

  useEffect(() => {
    if (isClient && tickerRef.current) {
      const { height } = tickerRef.current.getBoundingClientRect();
      const duration = height / 150;
      tickerTimelineRef.current = gsap.timeline({ repeat: -1 });
      tickerTimelineRef.current.fromTo(tickerRef.current, { y: '-50%' }, {
        duration,
        ease: 'none',
        y: '0%',
      });
      return () => {
        tickerTimelineRef.current.kill();
        tickerTimelineRef.current = null;
      };
    }
  }, []);

  useEffect(() => {
    if (tickerTimelineRef.current) {
      if (inView) {
        tickerTimelineRef.current.play();
      } else {
        tickerTimelineRef.current.pause();
      }
    }
  }, [inView]);

  return (
    <div className="desktop-vertical-ticker" ref={inViewRef}>
      <div className="ticker-text" ref={tickerRef}>
        <VerticalTickerText />
        <VerticalTickerText />
        <VerticalTickerText />
        <VerticalTickerText />
        <VerticalTickerText />
        <VerticalTickerText />
      </div>
    </div>
  );
};

export default DesktopTicker;
