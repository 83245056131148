import React from 'react';
import Flickity from 'react-flickity-component';
import { Section, SanityImage } from '../common';
import './ProfilesSlice.scss';

const Profile = ({
  name,
  bio,
  greyScale,
  photo,
}) => (
  <div className="profile">
    <div className="image-wrapper">
      <SanityImage
        className={`profile-image${greyScale ? ' greyscale' : ''}`}
        image={photo}
        alt={`A photo of ${name}`}
      />
    </div>
    <h3 className="profile-name">{name}</h3>
    <p className="profile-bio">{bio.split(/\n\n/).map(line => <span key={line}>{line}</span>)}</p>
  </div>
);

const flickityOptions = {
  prevNextButtons: false,
  pageDots: false,
  cellAlign: 'left',
  contain: 'true',
};

const ProfilesSlice = ({ slice }) => {
  const { title, profiles, greyscaleFilter } = slice;
  return (
    <section className="profiles-slice">
      <Section hasPadding hasBottomBorder noGutters>
        <Section>
          <h2 className="title">{title}</h2>
        </Section>
        <div className="profiles-container">
          <Flickity
            className="slider"
            options={flickityOptions}
            static
          >
            {profiles.map(profile => (
              <Profile key={profile.id} greyScale={greyscaleFilter} {...profile} />
            ))}
          </Flickity>
        </div>
      </Section>
    </section>
  );
};

export default ProfilesSlice;
