import React, { useMemo } from 'react';
import ErrorBoundary from './ErrorBoundary';

import {
  VideoStreamSlice,
  AboutSlice,
  ManifestoSlice,
  ProfilesSlice,
} from './Slices';

const sliceComponentSelector = {
  videoStreamSlice: VideoStreamSlice,
  aboutSlice: AboutSlice,
  manifestoSlice: ManifestoSlice,
  profilesSlice: ProfilesSlice,
};

const Slices = ({ slices, location }) => {
  const sliceComponents = useMemo(() => {
    if (!slices) return null;
    return (
      <div className="slice-master">
        {slices.map((slice) => {
          const Component = sliceComponentSelector[slice.type];
          if (!Component) return null;
          return (
            <ErrorBoundary key={`slice-${slice.id}`}>
              <Component slice={slice} location={location} />
            </ErrorBoundary>
          );
        })}
      </div>
    );
  }, [slices]);

  return sliceComponents;
};

export default Slices;
