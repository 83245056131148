import React from 'react';
import { Section, SplitWordsAnimation } from '../../common';
import './VideoMeta.scss';

const VideoMeta = ({ playing, muted, currentTrack, onToggleMuted }) => {
  if (!currentTrack) return null;
  const {
    speaker,
    title,
  } = currentTrack;

  return (
    <div className="video-meta">
      <Section className={`preview-meta ${!playing ? 'show' : 'hide'}`} hasGrid>
        <div className="container">
          <SplitWordsAnimation>
            <span className="speaker">
              {speaker}
            </span>
          </SplitWordsAnimation>
          <SplitWordsAnimation>
            <h1 className="video-title">
              {title}
            </h1>
          </SplitWordsAnimation>
        </div>
      </Section>
      <Section className={`play-meta ${!playing ? 'hide' : 'show'}`}>
        <div className="container">
          <span className="video-title">{title}</span>
          <span className="speaker">{speaker}</span>
          <button className="toggle-muted-button" type="button" onClick={onToggleMuted}>
            <span>{muted ? 'Listen' : 'Mute'}</span>
          </button>
        </div>
      </Section>
    </div>
  );
};

export default VideoMeta;
