import React, { useEffect, useRef, useState } from 'react';
import SanityImage from 'gatsby-plugin-sanity-image';
import './SanityImage.scss';

const SanityImageWrapper = (props) => {
  const {
    image,
    className = '',
    style = {},
    onLoaded,
  } = props;

  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    if (onLoaded && imgRef.current && imgRef.current.state.imgLoaded) {
      onLoaded();
    }
  }, [imgRef.current, loaded]);

  return (
    <div className="sanity-image-wrapper">
      <SanityImage
        {...image}
        className={className}
        alt={image.alt || ''}
        style={style}
        ref={imgRef}
        onLoad={() => setLoaded(true)}
      />
    </div>
  );
};

export default SanityImageWrapper;
