import React from 'react';
import { Section, RichText, SocialLink } from '../common';
import './Footer.scss';

const Footer = ({ settings }) => {
  const {
    footerContact,
    footerHeadline,
    socialLinks,
  } = settings;

  return (
    <footer className="footer">
      <Section hasGrid>
        <div className="col col-left">
          <span className="headline">{footerHeadline}</span>
        </div>
        <div className="col col-right">
          <div className="container">
            <RichText className="contact-text" blocks={footerContact || []} />
            <div className="socials">
              {socialLinks && socialLinks.map(socialLink => (
                <SocialLink
                  key={socialLink.url}
                  title={socialLink.title}
                  url={socialLink.url}
                />
              ))}
            </div>
          </div>
        </div>
      </Section>
    </footer>
  );
};

export default Footer;
