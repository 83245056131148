import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import gsap from 'gsap';
import VideoMeta from './VideoMeta';
import PlaylistMenu from './PlaylistMenu';
import { SanityImage } from '../../common';
import { ReactComponent as PlayIcon } from '../../../assets/images/PlayButton.svg';
import { ReactComponent as Spinner } from '../../../assets/images/Spinner.svg';
import './VideoStreamSlice.scss';
import useHlsPlayer from '../../../hooks/useHlsPlayer';
import useIsMobile from '../../../hooks/useIsMobile';
// import useBodyScrollLock from '../../../hooks/useBodyScrollLock';
import { isClient } from '../../../utils/helpers';
import useCurrentTrackIndex from '../../../hooks/useCurrentTrackIndex';

const VideoStreamSlice = ({ slice, location }) => {
  const {
    playlist,
  } = slice;
  const [menuOpen, setMenuOpen] = useState(false);

  const isMobile = useIsMobile();

  // const ref = useBodyScrollLock(menuOpen);
  const playerRef = useRef(null);
  const timerRef = useRef();
  const curtainRef = useRef();
  const posterRef = useRef();
  const timerBufferProgressRef = useRef();
  const timerProgressRef = useRef();
  const timerCurrentTimeRef = useRef();

  const [playing, setPlaying] = useState(true);
  const [muted, setMuted] = useState(true);
  const [videoReady, setVideoReady] = useState(false);
  const [duration, setDuration] = useState();

  const {
    trackIndex,
    setTrackIndex,
    streamSeconds,
    videoRanges,
    trackSeconds,
    totalPlaylistDuration,
    numTracks
  } = useCurrentTrackIndex(playlist);

  const [currentTrack, setCurrentTrack] = useState(playlist[trackIndex]);
  const currentSrc = `https://stream.mux.com/${currentTrack.video.asset.playbackId}.m3u8`;
  const [source, setSource] = useState(currentSrc);

  useHlsPlayer(playerRef, currentSrc);

  const onToggleMuted = useCallback(() => {
    setMuted((value) => {
      playerRef.current.muted = !value;
      return !value;
    });
  }, []);

  const onVideoReady = useCallback(() => {
    setVideoReady(value => !value);
  }, []);

  const togglePlaylistMenu = useCallback(() => {
    setMenuOpen((value) => {
      if (!value && isClient) {
        document.body.classList.add('modal-open');
      } else {
        document.body.classList.remove('modal-open');
      }
      return !value;
    });
  }, []);

  const updateTimerProgress = (percent) => {
    timerProgressRef.current.style.transform = `translate(-${100 - percent}%, 0)`;
    timerCurrentTimeRef.current.style.left = `${percent}%`;
  };

  useEffect(() => {
    if (playerRef.current) {
      const onEnded = () => {
        playerRef.current.currentTime = 0;
        const nextTrackIndex = trackIndex < numTracks - 1 ? trackIndex + 1 : 0;
        setTrackIndex(nextTrackIndex);
        setCurrentTrack(playlist[nextTrackIndex]);
        setVideoReady(false);
        setPlaying(true);
        playerRef.current.play();
      };
      const onDurationChanged = () => {
        setDuration(playerRef.current.duration);
      };
      const onPlay = () => { setPlaying(true); };
      const onPause = () => { setPlaying(false); };

      const onLoadStart = () => {
        updateTimerProgress(0);
        timerBufferProgressRef.current.style.transform = 'translate(-100%, 0)';
      };

      const onTimeUpdate = (e) => {
        if (playerRef.current) {
          const percent = (playerRef.current.currentTime / playerRef.current.duration) * 100;
          updateTimerProgress(percent);
        }
      };

      playerRef.current.addEventListener('loadstart', onLoadStart);
      playerRef.current.addEventListener('loadeddata', onVideoReady);
      playerRef.current.addEventListener('ended', onEnded);
      playerRef.current.addEventListener('play', onPlay);
      playerRef.current.addEventListener('pause', onPause);
      playerRef.current.addEventListener('timeupdate', onTimeUpdate);
      // playerRef.current.addEventListener('progress', onProgress);
      playerRef.current.addEventListener('durationchange', onDurationChanged);
      return () => {
        playerRef.current.removeEventListener('loadstart', onLoadStart);
        playerRef.current.removeEventListener('loadeddata', onVideoReady);
        playerRef.current.removeEventListener('ended', onEnded);
        playerRef.current.removeEventListener('play', onPlay);
        playerRef.current.removeEventListener('pause', onPause);
        playerRef.current.removeEventListener('pause', onTimeUpdate);
        // playerRef.current.removeEventListener('progress', onProgress);
        playerRef.current.removeEventListener('durationchange', onDurationChanged);
      };
    }
  }, [trackIndex]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.currentTime = trackSeconds;
      playerRef.current.play();
    }
  }, []);

  useEffect(() => {
    if (posterRef.current) {
      gsap.set(posterRef.current, {
        opacity: 1,
      });
    }
  }, []);

  useEffect(() => {
    if (videoReady) {
      setTimeout(() => {
        gsap.to(posterRef.current, {
          opacity: 0,
          duration: 2,
          ease: 'expo.out',
        });
      }, 2000);
    }
  }, [videoReady]);

  return (
    <>
      <section id="video-stream" className="video-stream-slice">
        <button className={`toggle-menu-button  ${menuOpen ? 'open' : 'closed'}`} type="button" onClick={togglePlaylistMenu}>
          <span>{menuOpen ? 'Close' : 'Up Next'}</span>
        </button>
        <div className="video-wrapper">
          <button
            type="button"
            className={`toggle-play-button ${muted ? 'show' : 'hide'}`}
            onClick={onToggleMuted}
            aria-label="Unmute the audio for the video"
          >
            <PlayIcon />
          </button>
          {!videoReady && !muted && (
            <div className="loading-spinner">
              <Spinner />
            </div>
          )}
          <div className="poster" ref={posterRef}>
            {isMobile ? (
              <SanityImage
                className="mobile-poster"
                image={currentTrack.mobilePosterFixed}
                alt={`A preview thumbnail of "${currentTrack.title}"`}
              />
            ) : (
              <SanityImage
                className="desktop-poster"
                image={currentTrack.posterFixed}
                alt={`A preview thumbnail of "${currentTrack.title}"`}
              />
            )}
          </div>
          <video
            ref={playerRef}
            src={currentSrc}
            autoPlay
            playsInline
            muted
            onClick={onToggleMuted}
          />
          <div className={`progress-bar ${muted ? 'hide' : 'show'}`}>
            <div className="timer" ref={timerRef}>
              <div className="timer-wrapper">
                <span className="buffer-progress" ref={timerBufferProgressRef} />
                <span className="timer-progress" ref={timerProgressRef} />
              </div>
              <span className="current-time" ref={timerCurrentTimeRef} />
            </div>
          </div>
        </div>
        <VideoMeta muted={muted} playing={!muted} currentTrack={currentTrack} onToggleMuted={onToggleMuted} />
      </section>
      <PlaylistMenu
        trackIndex={trackIndex}
        playlist={playlist}
        videoRanges={videoRanges}
        streamSeconds={streamSeconds}
        totalPlaylistDuration={totalPlaylistDuration}
        menuOpen={menuOpen}
      />
    </>
  );
};

export default VideoStreamSlice;
