import React, { useMemo } from 'react';
import {
  MediumIcon,
  InstagramIcon,
  PodcastIcon,
  TwitterIcon,
} from '../../assets/images/socials';
import { toKebabCase } from '../../utils/helpers';
import './SocialLink.scss';

const MEDIUM_TYPE = 'Medium';
const INSTAGRAM_TYPE = 'Instagram';
const PODCAST_TYPE = 'Anchor';
const TWITTER_TYPE = 'Twitter';

const SocialLink = (props) => {
  const {
    title,
    url,
  } = props;
  const typeClass = toKebabCase(title);
  const Icon = useMemo(() => {
    switch (title) {
      case MEDIUM_TYPE:
        return (
          <MediumIcon />
        );
      case PODCAST_TYPE:
        return (
          <PodcastIcon />
        );
      case INSTAGRAM_TYPE:
        return (
          <InstagramIcon />
        );
      case TWITTER_TYPE:
        return (
          <TwitterIcon />
        );
      default:
        return null;
    }
  }, []);
  return (
    <a
      className={`social-link ${typeClass}`}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={`Open a new tab to ${title}`}
    >
      {Icon}
    </a>
  );
};

export default SocialLink;
